import React from "react";
import AppsIcon from "@material-ui/icons/Apps";
import "./Nav.css";
// import component 👇
import Drawer from "react-modern-drawer";
import { Link } from "react-router-dom";

//import styles 👇
import "react-modern-drawer/dist/index.css";

function Nav() {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <div>
      <div className="clr">
        <div className="col-md-10 mx-auto">
          <nav className="navbar navbar-expand-sm ">
            {/* Brand */}

            <a className="navbar-brand">
              <Link to="/">
                <img className="nav-img" src="/images/01.png" />
              </Link>
            </a>

            {/* Links */}
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a className="nav-link ok" onClick={toggleDrawer}>
                  Word Lists
                </a>
              </li>

              <li className="nav-item">
                <a className="nav-link ok" onClick={toggleDrawer}>
                  Word Finders
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link">
                  <AppsIcon onClick={toggleDrawer} />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <Drawer
        className="drawer"
        open={isOpen}
        onClose={toggleDrawer}
        direction="left"
        style={{ overflow: "scroll" }}
      >
        <div className="header mx-auto">
          <img className=" drawer-img mx-auto" src="/images/01.png" />
        </div>
        <div>
          <h3 className="drawer-header">Word Lists</h3>
        </div>
        <div className="drawer-list">
          <small>Word Scrambler</small>
          <small>Anagram Solver</small>
          <small>Word Unscrambler</small>
          <small>Words with Friends Cheat</small>
          <small>Scrabble Word Finder</small>
        </div>
        <div>
          <h3 className="drawer-header">Dictonary</h3>
        </div>
        <div className="drawer-list">
          <small>Word With Friends Dictonary</small>
          <small>Scrabble Dictonary</small>
        </div>
        <div>
          <h3 className="drawer-header">List of Words</h3>
        </div>
        <div className="drawer-list">
          <small>All Consonant Words</small>
          <small>Vowel Words</small>
          <small>Words By Length</small>
        </div>
        <div>
          <h3 className="drawer-header">Tools</h3>
        </div>
        <div className="drawer-list">
          <small>Password Generator</small>
          <small>Username Generator</small>
          <small>Random Word Generator</small>
        </div>
        <div>
          <h3 className="drawer-header">Games</h3>
        </div>
        <div className="drawer-list">
          <small>Scramble Words</small>
          <small>Codeword</small>
          <small>Outspell</small>
        </div>
      </Drawer>
    </div>
  );
}

export default Nav;
