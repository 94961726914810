import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="footer">
      <div className="row align-items-start">
        <div className="col-md-3 ">
          <img
            src="/images/01.png"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginLeft: "60px",
              marginTop: "5px",
              marginBottom: "30px",
              height: "60px",
              width: "100px",
            }}
          />
        </div>
        <div className="col-md-3 ">
          <ul>
            <li
              style={{ textTransform: "uppercase" }}
              className="mb-2 separate-bottom-links"
            >
              Resources
            </li>
            <li className="separate-bottom-links">Articles</li>
            <li className="separate-bottom-links">Popular Pages</li>
            <li className="separate-bottom-links">Top Search</li>
            <li className="separate-bottom-links">External Resources</li>
            <li className="separate-bottom-links">Tools</li>
            <li className="separate-bottom-links">Definitions</li>
          </ul>
        </div>
        <div className="col-md-3">
          <ul>
            <li
              style={{ textTransform: "uppercase" }}
              className="mb-2 separate-bottom-links"
            >
              About
            </li>
            <Link className="separate-bottom-links" to="/contact">
              <li style={{ color: "white", listStyleType: "none" }}>Contact</li>
            </Link>
            <Link className="separate-bottom-links" to="/privacy">
              <li style={{ color: "white", listStyleType: "none" }}>Privacy</li>
            </Link>{" "}
            <Link className="separate-bottom-links" to="/terms">
              <li style={{ color: "white", listStyleType: "none" }}>Terms</li>
            </Link>
            <Link className="separate-bottom-links" to="/faq">
              <li style={{ color: "white", listStyleType: "none" }}>FAQ</li>
            </Link>
          </ul>
        </div>
        <div className="col-md-3 mb-1">
          <ul>
            <li
              style={{ textTransform: "uppercase" }}
              className="separate-bottom-links mb-2"
            >
              LINKS
            </li>
            <li className="separate-bottom-links">Anagram Solver</li>
            <li className="separate-bottom-links">WordFinderx</li>
            <li className="separate-bottom-links">Letter Solver</li>
            <p className="separate-bottom-links">Free Games</p>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
