import React from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import Nav from "./components/Nav";
import Homepage from "./components/Homepage";
import Footer from "./components/Footer";
import Result from "./components/Result";
import Lengthpage from "./components/Lengthpage";
import Blog from "./components/Blog";
import Lengthpage3 from "./components/Lengthpage3";
import Lengthpage4 from "./components/Lengthpage4";
import Lengthpage5 from "./components/Lengthpage5";
import Lengthpage6 from "./components/Lengthpage6";
import Contact from "./components/Contact";
import Privacy from "./components/Privacy";
import Terms from "./components/Terms";
import Faq from "./components/Faq";
import { useEffect } from "react";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Nav />
        <Switch>
          <Route exact path="/" component={Homepage} />
        </Switch>
        <Switch>
          <Route exact path="/result" component={Result} />
        </Switch>
        <Switch>
          <Route exact path="/length" component={Lengthpage} />
        </Switch>
        <Switch>
          <Route exact path="/length3" component={Lengthpage3} />
        </Switch>
        <Switch>
          <Route exact path="/length4" component={Lengthpage4} />
        </Switch>
        <Switch>
          <Route exact path="/length5" component={Lengthpage5} />
        </Switch>
        <Switch>
          <Route exact path="/length6" component={Lengthpage6} />
        </Switch>
        <Switch>
          <Route exact path="/blog" component={Blog} />
        </Switch>
        <Switch>
          <Route exact path="/contact" component={Contact} />
        </Switch>
        <Switch>
          <Route exact path="/privacy" component={Privacy} />
        </Switch>
        <Switch>
          <Route exact path="/terms" component={Terms} />
        </Switch>
        <Switch>
          <Route exact path="/faq" component={Faq} />
        </Switch>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
