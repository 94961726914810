import React, { useState } from "react";
import Popup from "reactjs-popup";
import "./WordsPattern.css";
import CloseIcon from "@material-ui/icons/HighlightOff";
import searchService from "../../services/search-service";
export default function WordsPattern({ wordList, length, heading }) {
  const [open, setOpen] = useState(false);
  const [modalWordDetails, setModalWordDetails] = useState({});
  const [modalWordName, setModalWordName] = useState("");
  const [err, setErr] = useState(false);
  const getDefinitions = (word) => {
    setModalWordName(word);
    searchService
      .getDictionary(word)
      .then((res) => {
        setModalWordDetails(res.data);
      })
      .catch((err) => {
        setErr(true);
      });
  };
  return (
    <div className="words-card list-card card mb-4">
      <div
        className="card-header"
        style={{ paddingRight: "30px", paddingLeft: "30px" }}
      >
        <h3 className="m-0 card-heading">{length} Letter words</h3>
      </div>
      <div
        className="card-body result-card-body "
        style={{ paddingRight: "30px", paddingLeft: "30px" }}
      >
        {/* <div className="words-card-spacing"> */}

        {wordList &&
          wordList.map((item) => {
            return (
              <p
                onClick={() => {
                  setOpen(true);
                  getDefinitions(item.word);
                }}
                className="text-center  word-box"
              >
                {item.word}
                <sub>{item.points}</sub>
              </p>
            );
          })}
        {/* </div> */}
      </div>
      <Popup
        open={open}
        contentStyle={{
          backgroundColor: "transparent",
          border: "none",
          // minWidth: "600px",
          width: "auto",
        }}
        onClose={() => {
          setModalWordName("");
          setModalWordDetails({});
          setOpen(false);
          setErr(false);
        }}
        className="my-popup"
        modal
      >
        <div>
          <div className="col-md-10 mx-auto mt-5 mb-5 ">
            <div className="card border-0 list-card mt-4">
              <div className="card-header text-white d-flex align-items-center">
                <h3 className="m-0">
                  Definition for
                  <b className="badge badge-warning ml-1 text-uppercase">
                    {modalWordName}
                  </b>
                </h3>
                <CloseIcon onClick={() => setOpen(false)} className="ml-auto" />
              </div>
              {err ? (
                <div className="card-body text-center">
                  <h5>Something went wrong!</h5>
                </div>
              ) : modalWordDetails.existence ? (
                <>
                  <div className="card-body">
                    {}
                    <b className="popup-body-heading">Word Games </b>
                    <p className="popup-line">
                      Scrabble US/Canada (OTCWL){" "}
                      {modalWordDetails &&
                      modalWordDetails.existence &&
                      modalWordDetails.existence.us ? (
                        <span class="badge badge-success">Yes</span>
                      ) : (
                        <span class="badge badge-danger">No</span>
                      )}
                    </p>
                    <p className="popup-line">
                      Scrabble UK (SOWPODS){" "}
                      {modalWordDetails &&
                      modalWordDetails.existence &&
                      modalWordDetails.existence.uk ? (
                        <span class="badge badge-success">Yes</span>
                      ) : (
                        <span class="badge badge-danger">No</span>
                      )}
                    </p>
                    <p className="popup-line">
                      Words With Friends{" "}
                      {modalWordDetails &&
                      modalWordDetails.existence &&
                      modalWordDetails.existence.canada ? (
                        <span class="badge badge-success">Yes</span>
                      ) : (
                        <span class="badge badge-danger">No</span>
                      )}
                    </p>
                  </div>
                  <div style={{ overflowY: "auto", maxHeight: "60vh" }}>
                    {modalWordDetails &&
                      modalWordDetails.meanings &&
                      modalWordDetails.meanings.map((meaning, index) => {
                        // item.definitions &&
                        console.log("sas", meaning);

                        return (
                          <div key={index} className="card m-2 inner-card">
                            <div className="inner-modal-card-header font-italic p-2 d-flex align-items-center">
                              <h5 className="m-0">{meaning.typeOfSpeech}</h5>
                            </div>
                            <div className="card-body p-2">
                              {meaning.definitions.map((def, index) => (
                                <>
                                  <div className="mb-2">
                                    <span class="badge badge-success">
                                      Definition
                                    </span>
                                  </div>
                                  <div>
                                    <b className="">{def.definition}</b>
                                  </div>
                                  {def.synonyms && def.synonyms.length > 0 && (
                                    <div className="mb-2">
                                      <span class="badge badge-warning mt-3">
                                        Synonyms
                                      </span>
                                    </div>
                                  )}
                                  {def.synonyms &&
                                    def.synonyms.map((item, index) => (
                                      <span class="badge badge-secondary mr-2">
                                        <b className="">{item}</b>
                                      </span>
                                    ))}
                                  {def.example && def.example.length && (
                                    <div className="mb-4">
                                      <div className="mb-2 mt-3">
                                        <span class="badge badge-info">
                                          Examples
                                        </span>
                                      </div>
                                      <div className="text-secondary ml-2">
                                        - {def.example}
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </>
              ) : (
                <div className="card-body">
                  <div class="loader ml-auto mr-auto"></div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Popup>
    </div>
  );
}
