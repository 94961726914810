import React from "react";
import { Link } from "react-router-dom";
import "./Rightside.css";

function Rightside() {
  return (
    <div className="rideside">
      <div className="card list-card">
        <div className="card-header bg-primary text-light">
          <b>Word Finders</b>
        </div>
        <ul className="list-group card-list-group list-group-flush">
          <li className="my-list-group-item">Word Finder</li>
          <li className="my-list-group-item">Scrabble Word Finder</li>
          <li className="my-list-group-item">Words With Friends Cheat</li>
          <li className="my-list-group-item">Word Unscrambler</li>
          <li className="my-list-group-item">Anagram Solver</li>
        </ul>
      </div>

      <div className="card list-card">
        <div className="card-header bg-primary text-light">
          <b>Blog</b>
        </div>
        <div className="col-md-12 mt-4 mb-5">
          <Link style={{ textDecoration: "none" }} to="/blog">
            <img
              className="img-fluid"
              src="https://assets.word.tips/pictures/e74ad31c-3177-4170-bc18-4e288ed989eb_header3.png"
              alt="Blog 1"
            />
            <h4 className="blog-heading" style={{ color: "black" }}>
              Is _____ a Word?
            </h4>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Rightside;
