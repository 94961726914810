import React from "react";
import { Paper, TextField } from "@material-ui/core";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import "./Lengthpage.css";

const Lengthpage = () => {
  return (
    <div>
      <div className="rows">
        <div className="leftside">
          <Paper style={{ padding: "10px" }}>
            <div className="col-md-10 mx-auto my-5">
              <h1>2 Letter Words</h1>
              <p>
                Two-letter words. Settle down, breathe in and relax. We need
                your QI settled for this amazing new world of two lettered
                words! Words containing two letters can be so exciting and can
                get you raving around like a mighty ox! Make sure you check out
                our guide to Words with two letters; Two letter words, to get
                the upper hand on your Scrabble and Words with Friends
                opponents! Check out these useful lists too: Five letter words
                and four-letter words!
              </p>
            </div>
            <div style={{ marginBottom: "40px" }} className="col-md-10 mx-auto">
              <div className="card mt-4">
                <div className="card-header">
                  <h3>2 latters Words</h3>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-1">
                      <span className="badge badge-secondary">
                        <p className="text-center m-auto p-2">US</p>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Paper>
        </div>
        <div className="rightside">
          <Paper style={{ marginTop: "20px" }}>
            <div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexGrow: 1,
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <FormLabel
                  style={{ marginTop: "20px", padding: "10px" }}
                  component="legend"
                >
                  <h2 className="ml-5">Filter Result</h2> <hr></hr>
                </FormLabel>
                <TextField
                  placeholder="Your Latter"
                  style={{
                    width: "97%",
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                  variant="outlined"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginBottom: "20px",
                }}
              >
                <TextField placeholder="Starts" variant="outlined" />
                <TextField placeholder="End" variant="outlined" />
              </div>
              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  marginBottom: "20px",
                }}
              >
                <TextField placeholder="Contains" variant="outlined" />
                <TextField placeholder="Length" variant="outlined" />
              </div>
              <button
                style={{
                  width: "95%",
                  marginLeft: "8px",
                  marginBottom: "20px",
                }}
                className="btn btn-primary"
              >
                Search
              </button>
            </div>
          </Paper>
          <Paper>
            <div className="m-4 ml-5">
              <FormControl component="fieldset">
                <FormLabel style={{ marginTop: "20px" }} component="legend">
                  <h2>Sort Results</h2> <hr></hr>
                </FormLabel>
                <RadioGroup aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="Points"
                    control={<Radio />}
                    label="Points"
                  />
                  <FormControlLabel
                    value="A-Z"
                    control={<Radio />}
                    label="A-Z"
                  />
                  <FormControlLabel
                    value="Z-A"
                    control={<Radio />}
                    label="Z-A"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Paper>
          <Paper>
            <div className="m-4 ml-5">
              <FormControl component="fieldset">
                <FormLabel style={{ marginTop: "20px" }} component="legend">
                  <h2>Dictionary</h2>
                  <hr></hr>
                </FormLabel>
                <RadioGroup aria-label="gender" name="gender1">
                  <FormControlLabel
                    value="Points"
                    control={<Radio />}
                    label="Words With friend"
                  />
                  <FormControlLabel
                    value="A-Z"
                    control={<Radio />}
                    label="Srabble UK"
                  />
                  <FormControlLabel
                    value="Z-A"
                    control={<Radio />}
                    label="Scrabble US"
                  />
                  <FormControlLabel
                    value="Z-A"
                    control={<Radio />}
                    label="All Dictonaries"
                  />
                </RadioGroup>
              </FormControl>
            </div>
          </Paper>
        </div>
      </div>
    </div>
  );
};

export default Lengthpage;
