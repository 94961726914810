import { Paper, TextField } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import "./Results.css";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import WordsPattern from "../utilities/WordsPattern/WordsPattern";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import Filters from "./Filters";
import { Select } from "@material-ui/core";
import useStore from "../store/store";
import Skeleton from "react-loading-skeleton";
import qs from "qs";

const Result = (props) => {
  const {
    setState,
    getData,
    sort,
    results,
    loading,
    dictionary,
    dictionaryOptions,
    sortOptions,
  } = useStore();
  const changeUrlQueryString = (name, value) => {
    let query = qs.parse(props.location.search);
    query[name] = value;
    console.log(query);
    props.history.push({
      pathname: "/result",
      search: qs.stringify(query, { encodeValuesOnly: true }),
    });
  };

  const getSearchResults = () => {
    let query = qs.parse(props.location.search);
    console.log(query);
    getData(query);
  };

  useEffect(() => {
    getSearchResults();
  }, [props.location.search]);

  return (
    <>
      <div className="mobile-filters">
        {/* <div className="filter-container"> */}
        <Filters mobileLayout changeQuery={changeUrlQueryString} />

        {/* </div> */}
      </div>
      <div className="container-fluid mobile-container">
        <div className="row result-row justify-content-evenly align-items-start">
          <div className="col-lg-8 col-md-7 d-block col-sm-6 leftbar">
            {/* <Paper className="col-md-12 p-2 paper"> */}
            <div className="result">
              {results.length ? (
                results.map((item) => (
                  <WordsPattern
                    words={["hello", "asdsd", "sasdw", "asdds"]}
                    heading="5 letter words"
                    wordList={item.word_list}
                    length={item.length}
                    numWords={item.num_words}
                  />
                ))
              ) : loading ? (
                <>
                  <div className="mb-5">
                    <h3>
                      <Skeleton />
                    </h3>
                    <h1>
                      <Skeleton />
                    </h1>
                    <Skeleton count={3} />
                  </div>
                </>
              ) : (
                <div className="words-card list-card card mb-4">
                  <div
                    className="card-body result-card-body d-block text-center"
                    style={{ paddingRight: "30px", paddingLeft: "30px" }}
                  >
                    {/* <div className="words-card-spacing"> */}

                    <h3 className="m-0 py-5">No Words Found</h3>
                    {/* </div> */}
                  </div>
                </div>
              )}

              {/* <div className="buttons">
                <button className="btn btn-primary">Add wildCard</button>

                <button className="btn btn-primary">Remove Filters</button>

                <button className="btn btn-primary">
                  Search All Dictonaries
                </button>
              </div>
              <hr></hr>
              <p className="text-center">
                If you think you found a bug or want to report a missing word,
                please send us your feedback
              </p>
              <p className="text-center">
                <button className="btn btn-primary"> Feedback</button>
              </p> */}
            </div>
            {/* </Paper> */}
          </div>

          <div className="col-lg-4 col-md-5 col-sm-6 rightbar">
            <div
              style={{ marginBottom: "20px" }}
              className="col-md-12 p-0 my-border-box mx-auto scroll-fixed-box my-z-indx"
            >
              <Filters sideLayout />
            </div>
            <div
              style={{ marginBottom: "20px" }}
              className="col-md-12 p-0 my-border-box mx-auto scroll-fixed-box"
            >
              <div className="p-4">
                <FormControl component="fieldset">
                  <FormLabel style={{ marginTop: "20px" }} component="legend">
                    <h2 className="result-box-title">Sort Results</h2> <hr></hr>
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    value={sort}
                    onChange={(e) => {
                      setState({ sort: e.target.value });
                      changeUrlQueryString("sort", e.target.value);
                    }}
                    name="sort"
                  >
                    {sortOptions.map((item, indx) => (
                      <FormControlLabel
                        value={item.value}
                        control={<Radio className="radio" />}
                        label={item.label}
                        className="mb-0"
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div
              style={{ marginBottom: "20px" }}
              className="col-md-12 p-0 my-border-box mx-auto scroll-fixed-box"
            >
              <div className="p-4">
                <FormControl component="fieldset">
                  <FormLabel
                    style={{ marginTop: "20px", marginBottom: "0px" }}
                    component="legend"
                  >
                    <h2 className="result-box-title">Dictionary</h2>
                    <hr></hr>
                  </FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    value={dictionary}
                    onChange={(e) => {
                      setState({ dictionary: e.target.value });
                      changeUrlQueryString("dictionary", e.target.value);
                    }}
                    name="dictionary"
                  >
                    {dictionaryOptions.map((item, indx) => (
                      <FormControlLabel
                        value={item.value}
                        control={<Radio className="radio" />}
                        label={item.label}
                        className="mb-0"
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Result;
