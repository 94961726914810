import React from "react";
import "./Home.css";
import SearchIcon from "@material-ui/icons/Search";
import "./Home.css";
import { Input, Paper } from "@material-ui/core";
import Filters from "./Filters";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import Search from "@material-ui/icons/Search";

function Home() {
  return (
    <div className="home">
      <div className="col-md-10 col-sm-12 mx-auto">
        <Fade bottom>
          <h1 className="heading  mx-auto text-center">Word Finder</h1>
          <span className="span-tag text-center">
            Enter up to 15 letters and up to 2 wildcards (? or space).
          </span>
          <p className="p-tag">Enter up to 3 wildcards (? or space)</p>
        </Fade>

        <div className="filter ">
          {/* <Paper
            elevation={3}
            style={{
              backgroundColor: "#f2f7f4",
              marginTop: "30px",
              marginBottom: "30px",
              borderRadius: "15px",
            }}
          > */}
          <Filters redirectSearch />
          {/* </Paper> */}
          <div className="free-space"></div>
        </div>
      </div>
    </div>
  );
}

export default Home;
