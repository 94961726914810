import React from "react";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import "./Contact.css";

const Contact = () => {
  return (
    <div>
      <div className="rows ">
        <div className="left col-md-12 mx-auto mt-4">
          <div className="text-center">
            <h3>Contact Form</h3>
          </div>
          <form>
            <div className="form-group">
              <label htmlFor="exampleFormControlInput1">Email address</label>
              <input
                type="email"
                className="form-control"
                id="exampleFormControlInput1"
                placeholder="name@example.com"
              />
            </div>
            <div className="form-group">
              <label htmlFor="exampleFormControlTextarea1">Message</label>
              <textarea
                className="form-control"
                id="exampleFormControlTextarea1"
                rows={3}
                defaultValue={""}
              />
            </div>
            <button type="submit" className="btn btn-primary">
              Send Message
            </button>
          </form>
        </div>
        <div className="right">
          <div className="col=md-10 p-5">
            <div className="card">
              <div className="card-header bg-primary text-light">
                <b>Word by Length</b>
              </div>
              <ul className="list-group list-group-flush">
                <Link to="/length">
                  <li className="list-group-item">2 latters word</li>
                </Link>
                <Link to="/length3">
                  <li className="list-group-item">3 latters word</li>
                </Link>
                <Link to="/length4">
                  <li className="list-group-item">4 latters word</li>
                </Link>
                <Link to="/length5">
                  <li className="list-group-item">5 latters word</li>
                </Link>
                <Link to="/length6">
                  <li className="list-group-item">6 latters word</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-md-12 pl-5 pr-5 pb-5">
            <div className="card">
              <div className="card-header bg-primary text-light">
                <b>Word Finder</b>
              </div>
              <ul className="list-group list-group-flush">
                <Link>
                  <li className="list-group-item">Word Finder</li>
                </Link>
                <Link>
                  <li className="list-group-item">Scrabble Word Finder</li>
                </Link>
                <Link>
                  <li className="list-group-item">Words With Friends Cheat</li>
                </Link>
                <Link>
                  <li className="list-group-item">Word Unscrambler</li>
                </Link>
                <Link>
                  <li className="list-group-item">Anagram Solver</li>
                </Link>
                <Link>
                  <li className="list-group-item">Word Scramble</li>
                </Link>
                <Link>
                  <li className="list-group-item">Jumble Solver</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-md-12 px-5 pb-5">
            <div className="card">
              <div className="card-header bg-primary text-light">
                <b>Dictionaries</b>
              </div>
              <ul className="list-group list-group-flush">
                <Link>
                  <li className="list-group-item">
                    Words With Friends Dictionary
                  </li>
                </Link>
                <Link>
                  <li className="list-group-item">Scrabble Dictionary</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-md-12 px-5 pb-5 ">
            <div className="card">
              <div className="card-header bg-primary text-light">
                <b>List of Words</b>
              </div>
              <ul className="list-group list-group-flush">
                <Link to="">
                  <li className="list-group-item">All Consonant Words</li>
                </Link>
                <Link to="">
                  <li className="list-group-item">Vowel Words</li>
                </Link>
                <Link to="">
                  <li className="list-group-item">Words By Length</li>
                </Link>
                <Link to="">
                  <li className="list-group-item">Words Ending In</li>
                </Link>
                <Link to="">
                  <li className="list-group-item">Words Start With</li>
                </Link>
              </ul>
            </div>
          </div>
          <div className="col-md-12 px-5 pb-5 ">
            <div className="card">
              <div className="card-header bg-primary text-light">
                <b>Popular Searches</b>
              </div>
              <ul className="list-group list-group-flush">
                <Link to="">
                  <li className="list-group-item">
                    2 Letter Words That Start With C
                  </li>
                </Link>
                <Link>
                  <li className="list-group-item">
                    4 Letter Words Ending In I
                  </li>
                </Link>
                <Link>
                  <li className="list-group-item">
                    4 Letter Words Ending In O
                  </li>
                </Link>
                <Link>
                  <li className="list-group-item">
                    {" "}
                    5 Letter Word Ending In A{" "}
                  </li>
                </Link>
                <Link>
                  <li className="list-group-item">Words Start With</li>
                </Link>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
