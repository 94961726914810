import { Paper } from "@material-ui/core";
import React from "react";

const Privacy = () => {
  return (
    <>
      <Paper elevation={3} className="col-md-8 mx-auto mt-5 mb-5 p-4">
        <div className="col-md-10 mx-auto">
          <h1 className="mt-3">Privacy Policy</h1>
          <hr></hr>
          <h3>1.WHO ARE WE?</h3>
          <p>
            We actually want you to read carefully our privacy policy. to ensure
            that you are fully informed. Having said that, if you have any
            question, please, contact with us:
          </p>
          <p>
            Controller:<b> CLIQPOD S.L.</b>
            <br></br>
            C/Arago 383, Planta 10, 08012, Barcelona, Spain<br></br>
            NIF: B-67255703<br></br>
            Email: contact@cliqpod.io{" "}
          </p>
          <h3 className="mt-5">
            2. FOR WHAT PURPOSES DO WE PROCESS YOUR DATA?
          </h3>
          <p>
            The collecting of your personal data is performed solely and
            exclusively for the following specific purposes. Whenever we process
            your personal data for the aforementioned purposes, we will need a
            legal ground.
          </p>
          <p>
            The General Data Protection Regulation (GDPR) mentions several legal
            grounds for the lawfulness of processing personal data. A lawful
            basis for processing personal data consists of at least one of these
            5 legal grounds and can vary per personal data processing activity
            and purpose.
          </p>
          <p className="ml-4">
            To create statistical reports with respect to the habits of access
            and the activity of the users of the Web Site, pursuant our cookies
            policy -> Lawful basis: Legitimate interest
          </p>
          <p className="ml-4">
            To resolve queries and suggestions -> Lawful basis: Legitimate
            interest
          </p>
          <p className="ml-4">
            To comply with legal obligations –> Lawful basis: Legal obligation
          </p>
          <p>
            Please, note that this privacy policy is subject to change from time
            to time. We recommend you to check it out, on a regular basis, so
            that you are always aware of the information we process.{" "}
          </p>
          <h3 className="mt-5">3. WE SHARE YOUR DATA?</h3>
          <p>
            Personal data may be shared with third parties to provide you with
            our services. We do our best to ensure these companies guarantee at
            least the same level of privacy protection as we provide.{" "}
          </p>
          <p>
            Furthermore, we may be obliged to communicate personal data to third
            parties such as Public Authorities.
          </p>
          <h3 className="mt-5">4. INTERNATIONAL TRANSFERS</h3>
          <p>
            Some of these entities are located outside the European Economic
            Area (EEA) (Iceland, Lichtenstein and Norwegian).{" "}
          </p>
          <p>
            In the case of The United States entities, which are listed under
            the EU-US Privacy Shield, the transfer of personal data to such
            entities by EU-based data controllers will be deemed adequate,
            without requiring the inclusion of additional safeguards, such as
            the use of EU Standard Contractual Clauses which are normally
            necessary to guarantee an adequate data protection level for
            international data transfers.
          </p>
          <h3 className="mt-5">5. DEADLINE FOR THE STORAGE OF YOUR DATA</h3>
          <p>
            We shall store your personal data as long as it is deemed necessary
            and depending on the type of data:
          </p>
          <p>
            Furthermore, we have implemented a variety of security measures to
            maintain safety your information. For example, we use encryption
            technology to protect your personal data.{" "}
          </p>
          <h3 className="mt-5">6. WHAT ARE YOUR RIGHTS AS A DATA SUBJECT?</h3>
          <p>
            The user may, at any time and free of charge, exercise his or her
            rights, according with the GDPR. We will be pleased to answer your
            requests.
          </p>
          <p>
            Can I ask about the specific personal data processed? (Right of
            access)
          </p>
          <p>
            Sure. Notwithstanding the data protection information provided
            above, you have the right to be told how we use your information and
            obtain access to your information.{" "}
          </p>
          <p>Can I ask to erasure my personal data? (Right to delete)</p>
          <p>
            In the case that you don’t want us to process your personal data
            anymore, you may request your personal data to be deleted. We will
            immediately delete your personal data and we will keep a copy that
            we will not use except if it is required by an Authority.{" "}
          </p>
          <p>
            The GDPR introduces the right to data portability. Because of the
            nature of services, we offer, we think you will not need to use this
            right. Still, if you need to use it sometime, you can contact us and
            we will go further on this matter.
          </p>
          <h3 className="mt-5">Contact </h3>
          <p>Email: contact@cliqpod.io</p>
          <h3 className="mt-2">
            CONTACT WITH THE SPANISH DATA PROTECTION AGENCY:{" "}
          </h3>
          <p>
            Spanish Data Protection Agency: Calle de Jorge Juan 6, CP 28001,
            Madrid, Spain (www.aepd.es)
          </p>
          <b>Last update: April 2020</b>
          <h3 className="mt-5">California Online Privacy Protection Act</h3>
          <p className="mb-5">
            {" "}
            CalOPPA is the first state law in the nation to require commercial
            websites and online services to post a privacy policy. The law's
            reach stretches well beyond California to require any person or
            company in the United States (and conceivably the world) that
            operates websites collecting Personally Identifiable Information
            from California consumers to post a conspicuous privacy policy on
            its website stating exactly the information being collected and
            those individuals or companies with whom it is being shared. For
            more information regarding your current opt-ins, click or see more
            at:
            http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf{" "}
          </p>{" "}
        </div>
      </Paper>
    </>
  );
};

export default Privacy;
