import React from "react";
import { Link } from "react-router-dom";
import "./Leftside.css";

function Leftside() {
  return (
    <div>
      <div className="top-title">
        <p className="text-center col-md-12 px-0  mt-3 mb-5">
          Word Finder is the perfect help when your vocabulary fails you. It's a
          unique word search tool, which finds all possible words made up of the
          letters you input. People use Word Finder for various reasons, but the
          primary one is to win games such as Scrabble and Words With Friends.
        </p>
      </div>
      <div className="top-left">
        <div className="img-tag">
          <img className="img-size" src="/images/02.jpg" />
        </div>
        <div className="word-finder ">
          <h1>Word Finder</h1>
          <p>
            Are you someone who loves finding and discovering new words? Are you
            a board game enthusiast? If you are, then this Word Finder is a tool
            you can't afford not to have. Whether you are into playing Scrabble,
            Words with Friends or any other word game, Word Finder will prove to
            be useful. It will help you both with word discovery, and as a
            reference tool, you and your playmates can use as a way to settle
            disputes about the validity of any particular word. Do you love
            boosting your vocabulary? Do word games help ignite your brain
            cells? Do you enjoy the thrill of trying to outwit an opponent with
            your encyclopedic mind? Then, our word generator is a tool that you
            simply can't live without!
          </p>
        </div>
      </div>
      <div className="row px-0">
        <div className="col-md-12 col-lg-6 ">
          <div
            className="card list-card"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-light  ">
              <b>Play Games</b>
            </div>
            <ul className="list-group card-list-group list-group-flush">
              <li className="my-list-group-item">Word Wipe</li>
              <li className="my-list-group-item">Crossword Puzzles</li>
              <li className="my-list-group-item">Outspell</li>
              <li className="my-list-group-item">Codeword</li>
              <li className="my-list-group-item">Scramble Words</li>
            </ul>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 ">
          <div className="card list-card">
            <div className="card-header bg-primary text-light">
              <b> Word Finders</b>
            </div>
            <ul className="list-group card-list-group list-group-flush">
              <li className="my-list-group-item">Word Finder</li>
              <li className="my-list-group-item">Scrabble Word Finder</li>
              <li className="my-list-group-item">Words With Friends Cheat</li>
              <li className="my-list-group-item">Word Unscrambler</li>
              <li className="my-list-group-item">Anagram Solver</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-12 px-0 mx-auto mt-4 mb-4">
        <h1>What is WordTips Word Finder?</h1>
        <p>
          If you haven’t had a chance to use Word Finder yet, then you’re in for
          a treat. This easy to work with online word generator will quickly and
          efficiently create a wide variety of words using the letters that you
          enter. Your excitement will grow as you watch it unscrambling the
          inputs you provide to make words out of letters as an expert. It will
          make Scrabble words and create interesting and unique combinations of
          letters. When compared with the range of online word generators, word
          find and word solver devices, Word Finder is among the most intuitive
          and simple to use. As you learn more about how using Word Finder can
          assist you in improving your language skills, spelling words properly
          and come up with new words from a jumble of letters, you’ll wonder how
          you ever managed without it.
        </p>
      </div>
      <div className="row px-0 align-items-start mt-2">
        <div className="col-md-12 col-lg-6 mx-auto">
          <div
            className="card list-card"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-light">
              <b>Tools</b>
            </div>
            <ul className="list-group card-list-group list-group-flush">
              <li className="my-list-group-item">Word Counter</li>
              <li className="my-list-group-item">Random Word Generator</li>
              <li className="my-list-group-item">Username Generator</li>
              <li className="my-list-group-item">Password Generator</li>
            </ul>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mx-auto">
          <div
            className="card list-card"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-light">
              <b>Word by Length</b>
            </div>
            <ul className="list-group card-list-group list-group-flush">
              <Link to="/length">
                <li className="my-list-group-item">2 latters word</li>
              </Link>
              <Link to="/length3">
                <li className="my-list-group-item">3 latters word</li>
              </Link>
              <Link to="/length4">
                <li className="my-list-group-item">4 latters word</li>
              </Link>
              <Link to="/length5">
                <li className="my-list-group-item">5 latters word</li>
              </Link>
              <Link to="/length6">
                <li className="my-list-group-item">6 latters word</li>
              </Link>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-12 px-0  mx-auto mt-4 mb-4">
        <h1>How to Use a Word Finder</h1>
        <p>
          Once you’ve decided on the game you’re going to play and who is going
          to participate, set your board up and then, go online to make sure
          Word Finder is readily accessible. Take care that everyone can use the
          tool privately without disclosing to other players what letters or
          tiles they have. When each person has her turn, first give them the
          time that they need to ask themselves: What words can I make with
          these letters? Providing players with the opportunity to come up on
          their own with word examples containing the vowels and consonants they
          have at the ready will strengthen their ability to play the game and
          acquire points on their own. Then, allow some time for the player to
          try to make works with their letters using Word Finder.
        </p>
      </div>
      <Link style={{ textDecoration: "none" }} to="/blog">
        <div className="col-md-12 px-0  mx-auto">
          <div
            className="card list-card"
            style={{ width: "", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-white ">
              <b>Blog</b>
            </div>
            <div className="row col-md-11 mx-auto">
              <div className="col-md-4 mt-4">
                <img
                  className="img-fluid"
                  src="https://assets.word.tips/pictures/e74ad31c-3177-4170-bc18-4e288ed989eb_header3.png"
                  alt="Blog 1"
                />
                <h4 className="blog-heading">Is _____ a Word?</h4>
              </div>
              <div className="col-md-4 mt-4 ">
                <img
                  className="img-fluid"
                  src="https://assets.word.tips/pictures/e74ad31c-3177-4170-bc18-4e288ed989eb_header3.png"
                  alt="Blog 1"
                />
                <h4 className="blog-heading">Is _____ a Word?</h4>
              </div>
              <div className="col-md-4 mt-4">
                <img
                  className="img-fluid"
                  src="https://assets.word.tips/pictures/e74ad31c-3177-4170-bc18-4e288ed989eb_header3.png"
                  alt="Blog 1"
                />
                <h4 className="blog-heading">Is _____ a Word?</h4>
              </div>
            </div>
          </div>
        </div>
      </Link>
      <div className="col-md-12 px-0  mx-auto mt-4 mb-4">
        <h1>When You Need to Up Your Game With a Word Game Helper</h1>
        <p className="mt-4">
          A word game helper can be invaluable for playing any word game that
          you can think of. Whether you are playing Scrabble, Words with
          Friends, Crossword Puzzles or any other word game, Word tips'
          extensive search engine will help you make words from letters that
          you've randomly picked. Games will get interminable when players are
          stuck and can’t imagine where they can place the bunch of vowels and
          consonants that they have available on the board.
        </p>
        <p className="mt-2">
          Maybe you have a regular evening game with your partner where a
          Scrabble word generator has been indispensable. Also, you may be one
          of the many families that usually play word board games on holidays
          and at gatherings with friends. If the latter is the case, then
          there’s more than likely players of different skill levels and ages
          involved. This is where a word game helper can assist in leveling the
          playing field. What’s more, you may have someone in your midst who
          likes to cheat a bit. Whether it’s done to tease other players or
          innocently by someone inexperienced with the game, an invention like
          Word Finder can be used to find words with the letters on hand.
        </p>
        <p className="mt-2">
          By searching the official Scrabble US, Scrabble UK and playable Words
          with Friends dictionaries, Word Finder will intuitively display your
          highest scoring option with the letters provided. Don't look at it as
          a word cheat, more of a reference tool you and your playmates can use
          to settle disputes about the validity of any particular word.
        </p>
      </div>
      <div className="row align-items-start px-0 mt-4">
        <div className="col-md-12 col-lg-6 mx-auto">
          <div
            className="card list-card"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-white">
              <b>Dictionaries</b>
            </div>
            <ul className="list-group card-list-group  list-group-flush">
              <li className="my-list-group-item">Scrabble Dictionary</li>
              <li className="my-list-group-item">
                Words With Friends Dictionary
              </li>
            </ul>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mx-auto">
          <div
            className="card list-card"
            style={{ width: "100%", marginTop: "10px" }}
          >
            <div className="card-header bg-primary text-white">
              <b>Word Finders</b>
            </div>
            <ul className="list-group card-list-group list-group-flush">
              <li className="my-list-group-item">Word Finder</li>
              <li className="my-list-group-item">Scrabble Word Finder</li>
              <li className="my-list-group-item">Words With Friends Cheat</li>
              <li className="my-list-group-item">Word Unscrambler</li>
              <li className="my-list-group-item">Anagram Solver</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="col-md-12 mx-auto mt-4 mb-4">
        <h1>Beat the Brain Freeze</h1>
        <p className="mt-4">
          Everyone needs a helping hand from time to time. We've all had those
          moments when a seemingly random mixture of tiles appears, making you
          ask, 'How do I make words with these letters'? The problem is now
          solved.
        </p>
        <p className="mt-2">
          With WordTips, there's no excuse in having leftover tiles stopping you
          from reaching your full potential. We'll show you how to make the best
          of those lingering letters, be it by creating a surprising two-letter
          combo or an impressive fifteen-letter giant!
        </p>
        <p className="mt-2">
          Be you a beginner or a pro, our intuitive database will always have
          the answer to your query. So, save the brain drain, frustration and
          arguments with fellow players, let our word finder be your constant
          companion.
        </p>
        <p className="mt-2">
          <b>Two Useful Word Finding Examples </b>
        </p>
        <p className="mt-2">
          <b>
            {" "}
            1.Player A has pulled the following letters in a game of Scrabble ─
            SNEOHYT.
          </b>
          <br></br>
          They’re able to swiftly unscramble the letters to make the word TONE.
          However, Word Finder comes up with a much bigger choice, including
          STONEY, HONEY, ETHNOS, HONEST, HONEYS AND HONESTY. As you can see, by
          laying down letters for the word HONESTY, Player A has a bonus of 50
          points for using all seven tiles at once in addition to the regular
          score for the play!
        </p>
        <p className="mt-2">
          <b>
            2.Player B is a child learning how to play Words with Friends and
            still growing their vocabulary.
          </b>
          <br></br>
          They’ve arranged these tiles on their rack ─ DOBMUYL. Right away, they
          might see the word DO, especially since it makes up the first two
          letters. They may also see the word MY after some examination. DO will
          give them three points while they’ll get seven points for MY, so
          that’s a step up. By encouraging them to then try Word Finder, they’ll
          be thrilled by the large range of words that they have to choose from
          ─ some made up of two letters to others using as many as six tiles.
          Not only will the young player improve their spelling skills but Word
          Finder introduces them to many words that they’re not familiar with so
          the educational potential is great!
        </p>
      </div>
      <div className="col-md-12 mx-auto mt-4 mb-4">
        <h2>Scrabble Words vs. Traditional Dictionaries</h2>
        <p className="mt-4">
          The two traditional English-language dictionaries are the
          Merriam-Webster dictionary used in the United States and the Oxford
          English Dictionary published in the United Kingdom. Dictionaries
          typically add new words each year. For instance, in 2018, the
          Merriam-Webster dictionary added 840 new words while the Oxford
          English dictionary expanded by 1,100 words. Having said that, word
          games rely on their own dictionaries that are tailored to the specific
          game and updated from time to time.
        </p>
        <p className="mt-2">
          Therefore, it’s critical to know what dictionary to refer to when
          you’re playing different word games so that you don’t inadvertently
          cheat! If you’ve played both Scrabble and Words with Friends, you’ll
          be aware that they have quite different rules when it comes to what
          words are acceptable for players to use. One example is that Words
          with Friends allows players to lay down tiles that spell many acronyms
          while Scrabble doesn’t permit such abbreviations. By using the
          Scrabble Word Finder or Words with Friends Cheat, you can be certain
          that you won’t go wrong!
        </p>
      </div>
    </div>
  );
}

export default Leftside;
