import axios from "axios";

const defaultOptions = {
  // baseURL: "https://timetracker.agency/api/v1",
  headers: {
    "Content-Type": "application/json",
    "access-control-allow-origin": "*",
    "access-control-allow-methods": "POST, GET, OPTIONS, PUT, DELETE",
    // "content-length": "1346",
    // "access-control-allow-headers":
    //   "Content-Type, Authorization, X-Requested-With",
    // "access-control-max-age": "86400",
    "access-control-allow-credentials": "true",
    Accept: "application/json",
  },
};

// Create instance
let instance = axios.create();
class SearchService {
  constructor() {
    this.apiBaseUrl = "https://timetracker.agency/api/v1";
  }

  convertUpperCase = (str) => {
    return str.toUpperCase(); // Returns "HELLO WORLD!"
  };

  getWords(data) {
    const { starts, ends, contains, length, dictionary, letters, sort } = data;
    const params = `${
      letters ? "&letters=" + this.convertUpperCase(letters) : ""
    }${starts ? "&starts_with=" + starts : ""}${
      ends ? "&ends_with=" + ends : ""
    }${contains ? "&contain=" + contains : ""}${
      length ? "&length=" + length : ""
    }${
      dictionary
        ? "&dictionary=" + (dictionary == "usa" ? "us" : dictionary)
        : ""
    }${sort ? "&word_sorting=" + sort : ""}`;
    return instance.get(this.apiBaseUrl + `/search?` + params);
  }

  getDictionary(word) {
    return instance.get(
      this.apiBaseUrl + `/definition?word=` + this.convertUpperCase(word)
    );
  }

  handleError(error) {
    console.log(error.message);
  }
}

export default new SearchService();
