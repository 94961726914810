import create from "zustand";
import SearchService from "../services/search-service";
const useStore = create((set) => ({
  letters: "",
  starts: "",
  ends: "",
  contains: "",
  length: "",
  dictionary: "uk",
  sort: "",
  sortOptions: [
    { label: "Points", value: "points" },
    { label: "A-Z", value: "az" },
    { label: "Z-A", value: "za" },
  ],
  dictionaryOptions: [
    { label: "Scrabble With Canada", value: "canada" },
    { label: "Scrabble With Uk", value: "uk" },
    { label: "Scrabble With US", value: "us" },
    { label: "Words With Friends ", value: "usa" },
    { label: "All", value: "all" },
  ],
  loading: false,
  results: [],
  setState: (state) => set(state),
  getData: (filters) => {
    set({ loading: true, results: [] });
    SearchService.getWords(filters)
      .then((res) => {
        console.log("res", res.data);
        set((state) => ({ results: res.data.word_sections }));
        set({ loading: false });
      })
      .catch((err) => {
        set((state) => ({ results: [] }));
      });
  },
}));
export default useStore;
