import { Paper } from "@material-ui/core";
import React from "react";

const Terms = () => {
  return (
    <>
      <Paper elevation={3} className="col-md-8 mx-auto mt-5 mb-5 p-4">
        <div className="col-md-10 mx-auto mt-4 mb-4">
          <h1>TERMS AND CONDITIONS</h1>
          <hr></hr>
          <p>
            We want your browsing to be as safe and transparent. Therefore,
            Terms and conditions here exposed are required to all users who
            access our website or use one of our games. When you browse the
            website or use our services, you accept these Terms and Conditions.
          </p>
          <h3 className="mt-5">1 IDENTIFICATION</h3>
          <b>CLIQPOD S.L. </b>
          <p>C/ Arago 383, Planta 10, 08012, Barcelona, Spain </p>
          <p>NIF: B-67255703</p>
          <p>Email: contact@cliqpod.io</p>
          <h3 className="mt-5">2 ACCESS </h3>
          <p>
            The Access to the website is free of charge and does not require
            registration.{" "}
          </p>
          <p>
            Access to the Website by minors is prohibited. However, in case of
            access to the Website and registration by a child under 18 years, it
            will be presumed that access has been made with prior and express
            consent of their parents, guardians or legal representatives.
          </p>
          <h3 className="mt-5">3 INTELLECTUAL PROPERTY RIGHTS</h3>
          <p>
            Cliqpod is the owner of or has obtained the corresponding license to
            exploit the intellectual property rights of the Website as well as
            the intellectual property rights according with the current
            legislation.
          </p>
          <p>
            All intellectual property rights to the contents of the Website are
            reserved and, in particular, it is prohibited to modify, copy,
            reproduce, publicly communicate, transform or distribute, by any
            means and in any form, all or part of the contents that are included
            in the Website, for public or commercial purposes, without the
            previous express and written authorization of Share Hive.
          </p>
          <p>
            If the user had become aware of the existence of any content that is
            unlawful, illegal, contrary to the current legislation or that may
            infringe any intellectual property right, he/she shall as soon as
            possible notify Share Hive.
          </p>
          <h3 className="mt-5">4 TERMS AND USE OF THE WEBSITE</h3>
          <p>
            It is not allowed and, therefore, the User shall be solely liable
            for its consequences, access or use of the Website for illegal or
            unauthorized purposes, with or without profit.
          </p>
          <p>
            Failure to comply with any of the foregoing obligations by the User
            may entail the establishment by Cliqpod of the appropriate measures
            permitted by the Law.
          </p>
          <h3 className="mt-5">5 LIABILITIES AND GUARANTEES</h3>
          <p>
            Cliqpod will provide the service with maximum efficiency, according
            to the means and technologies currently available. We try to
            minimize the impact of security incidents.{" "}
          </p>
          <p>
            Cliqpod reserves the right to block, modify, restrict, or
            temporarily or permanently interrupt access, browsing, use, housing
            and/or download of contents and/or services of the Website, with or
            without prior notice, to users that infringe any of the dispositions
            detailed in the present Conditions of Use and without the
            possibility that the users to claim for any type of compensation for
            this cause.
          </p>
          <h3 className="mt-5">6 GENERALS</h3>
          <p>
            The temporary validity of these Conditions of Use matches,
            therefore, with the moment of its presentation, until they are fully
            or partially modified, at which time the modified Conditions of Use
            will become effective.
          </p>
          <p>
            Regardless of the terms established in the Terms and Conditions,
            Cliqpod will be able to end, suspend or interrupt, in any moment and
            without having to notify previously, the access to the contents of
            the Website, without the possibility that the users request any type
            of compensation. After said extinction, the prohibitions on use of
            the contents previously exhibited in these Terms and Conditions
            shall remain in force.
          </p>
          <p>
            To the extent that any provision of the present Terms and Conditions
            is declared unenforceable or invalid under applicable law, totally
            or partially, by any competent Court or administrative body, said
            nullity or inapplicability shall not affect the other provisions of
            the Conditions of Use.
          </p>
          <p>
            Non-exercise or non-enforcement by Cliqpod of any right or provision
            of the present Conditions of Use shall not constitute waiver of the
            same in the absence of written recognition and agreement by the
            same.
          </p>
          <h3 className="mt-5">7 APPLICABLE LAW AND JURISDICTION</h3>
          <p>
            Whenever the current regulations foresee the possibility of both
            parties submitting to a specific jurisdiction, Cliqpod and the
            users, expressly waiving any other jurisdiction, submit the issue to
            Spanish legislation and to Courts and Tribunals in Madrid.
          </p>
          <p>
            If you want to make a complaint or claim about the present
            Conditions of Use, please let us know through the e-mail indicated
            in the paragraph: “Identification”.{" "}
          </p>
          <p>
            We are committed to resolve your concerns and to find a solution for
            the benefit of all parties.{" "}
          </p>
          <p>What really matters to us is your enjoyment.</p>
          <b className="mt-5 mb-5">Last update: April 2020.</b>
        </div>
      </Paper>
    </>
  );
};

export default Terms;
