import React from "react";

const Blog = () => {
  return (
    <div>
      <div className="col-md-10 mx-auto">
        <h1>Blog</h1>
        <img
          style={{ width: "100%" }}
          src="https://word.tips/pictures/befab622-da4c-493f-987b-cf25afe3ebcf_is_word_header.png?auto=compress,format&rect=2,0,1297,300&w=800&h=185"
        />
        <p>
          <b>Key Takeaways:</b>
        </p>
        <ul>
          <li>
            <p>
              Nearly 80% of respondents believed it was essential to learn and
              use proper English in general. Baby boomers were more likely to
              think so while Gen Z respondents were less likely.{" "}
            </p>
            <p>
              Respondents believed Facebook (49%) was the most detrimental to
              the English language, followed by Instagram (37%) and Tik Tok
              (34%).
            </p>
            <p>
              51% of respondents still felt optimistic about the future of the
              English language. Gen Z respondents were more likely to feel this
              way (63%), while baby boomers were not (37%).
            </p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Blog;
