import { Paper } from "@material-ui/core";
import React from "react";

const Faq = () => {
  return (
    <>
      <Paper elevation={3} className="col-md-8 mx-auto mt-5 mb-5 p-4">
        <div className="col-md-10 mx-auto">
          <h1 className="mt-5 mb-5">Scrabble, WWF and Anagrams FAQs</h1>
          <hr></hr>
          <h3>Anagrams Related Questions</h3>
          <p>What is an Anagram?</p>
          <p>
            An anagram is a new word or phrase created from the alphabets of
            another word or phrase. The general rule is to use all the letters
            from the original word or phrase to make new words. Let's give you a
            few examples to clear the air.{" "}
          </p>
          <p>
            Take the word 'silent.' Now if you have to make an anagram out of
            'silent' what would that be?
          </p>
          <li className="ml-5"> SILENT - LISTEN</li>
          <p>
            Now let's say you have the name 'Elvis.' What would be the anagram?
          </p>
          <li className="ml-5"> ELVIS - LIVES</li>
          <p>In the same way,</p>
          <li className="ml-5"> DIRTY ROOM- DORMITORY</li>
          <li className="ml-5"> ELBOW - BELOW</li>
          <li className="ml-5"> TASTE - STATE</li>
          <li className="ml-5 mb-3"> STUDY - DUSTY</li>
          <p>
            {" "}
            <b>Does an Anagram Have to Use All the Letters?</b>
          </p>
          <p>
            An anagram can be a word, phrase, title, or sentence. The standard
            rule is to use all the letters from the original word only once.
            That means you cannot use one letter two times or more. You cannot
            also skip using any letter- your anagram should use all alphabets of
            the original word only once.{" "}
          </p>
          <p>
            For example, let's say someone asks you to make anagram from the
            word 'heater.'
          </p>
          <p>Here, you have 6 letters - H, E, A, T, E, and R. </p>
          <p>So in your anagram, you have to use the 6 letters once. </p>
          <p>And the possible anagram can be- REHEAT.</p>
          <p>
            An anagram can be a word, phrase, title, or sentence. The standard
            rule is to use all the letters from the original word only once.
            That means you cannot use one letter two times or more. You cannot
            also skip using any letter- your anagram should use all alphabets of
            the original word only once.
          </p>
          <p>
            For example, let's say someone asks you to make anagram from the
            word 'heater.'
          </p>
          <p>Here, you have 6 letters - H, E, A, T, E, and R. </p>
          <p>So in your anagram, you have to use the 6 letters once. </p>
          <p>And the possible anagram can be- REHEAT.</p>
          <h3 className="mt-5">Scrabble Related Questions</h3>
          <p>
            <b>Which Scrabble Letter is Worth the Most?</b>
          </p>
          <p>
            Scrabble is one of the most popular word games ever made. Different
            letters in Scrabble have different points. The rule of thumb is that
            the less common is a letter, the higher are the points.{" "}
          </p>
          <p>
            Scrabble is one of the most popular word games ever made. Different
            letters in Scrabble have different points. The rule of thumb is that
            the less common is a letter, the higher are the points. That being
            said, in the traditional rules of Scrabble, the letters 'Q' and 'Z'
            have the highest points. You will get 10 points if you use 'Q' or
            'Z' in your moves. Do note that in the English version of the game
            you have only one 'Q' and 'Z.' In terms of point, 'J' and 'X' are
            right behind 'Q/Z' and gives you 8 points each. Some websites or
            apps may have different points for the letterse letters from the
            bag. At this point, there are two possibilities- A player has
            finished using all of his tiles Another player, Michael Cresta,
            earned 365 points with the word ' QUIXOTRY' in 2006.
          </p>
        </div>
      </Paper>
    </>
  );
};

export default Faq;
