import { Paper } from "@material-ui/core";
import React from "react";
import Home from "./Home";
import Leftside from "./Leftside";
import Rightside from "./Rightside";
import "./Homepage.css";

function Homepage() {
  return (
    <div className="homepage">
      <Home />
      <div className="left" style={{ marginTop: "20px" }}>
        <div className="rows col-md-11 mx-auto">
          <div className="left col-md-7 col-lg-8 col-sm-12">
            <Paper className="paper" elevation={3}>
              <Leftside />
            </Paper>
          </div>
          <div className="right col-md-5 ml-0 col-lg-8 col-sm-12">
            <Paper className="paper2" elevation={3}>
              <Rightside />
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Homepage;
