import { FormControl, Input, TextField } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import React, { useState } from "react";
import "./Filters.css";
import { Link } from "react-router-dom";
import InputAdornment from "@material-ui/core/InputAdornment";
import HelpOutline from "@material-ui/icons/HelpOutline";
import Search from "@material-ui/icons/Search";
import Cancel from "@material-ui/icons/Cancel";
import SearchService from "../services/search-service";
import useStore from "../store/store";
import { withRouter } from "react-router-dom";

function Filters(props) {
  const [touched, setTouched] = useState(false);
  const {
    setState,
    getData,
    starts,
    ends,
    sort,
    sortOptions,
    dictionary,
    dictionaryOptions,
    length,
    contains,
    letters,
  } = useStore();

  const getQuery = () => {
    let query = `${letters ? "&letters=" + letters : ""}${
      starts ? "&starts=" + starts : ""
    }${ends ? "&ends=" + ends : ""}${contains ? "&contains=" + contains : ""}${
      length ? "&length=" + length : ""
    }${dictionary ? "&dictionary=" + dictionary : ""}${
      sort ? "&sort=" + sort : ""
    }`;
    return query;
  };

  const handleChange = (e) => {
    setState({ [e.target.name]: e.target.value });
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        setTouched(true);
        setTimeout(() => {
          setTouched(false);
        }, 2000);
        letters &&
          props.history.push({
            pathname: "/result",
            search: getQuery(),
          });
      }}
    >
      <div
        className={`input-tag   ${
          props.sideLayout ? "side-layout-search px-4" : "mb-4"
        }`}
      >
        {/* <Input disableUnderline placeholder="Your latter" /> */}
        <Input
          id="input-with-icon-adornment"
          disableUnderline
          name="letters"
          value={letters}
          error={touched && !letters ? true : false}
          onChange={handleChange}
          placeholder="YOUR LETTERS"
          className="my-input"
          startAdornment={
            !props.sideLayout && (
              <InputAdornment position="start">
                <Link
                  to="/result"
                  style={{ cursor: "pointer", color: "unset" }}
                >
                  <Search />
                </Link>
              </InputAdornment>
            )
          }
        />
        {/* <Link to="/result" style={{ cursor: "pointer" }}>
            <SearchIcon className="searchicon" />
          </Link> */}
      </div>
      <div className="filter-container">
        <div className={`${props.sideLayout ? "px-4 pt-1 pb-4" : "p-4"}`}>
          <div className="row p-1 " style={{ justifyContent: "space-evenly" }}>
            <TextField
              className="text-field"
              placeholder="A"
              id="outlined-basic"
              label="Start"
              name="starts"
              type="text"
              value={starts}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                className: "text-field-input",
                endAdornment: (
                  <InputAdornment
                    className={`end-icon ${!starts && "show-tooltip"}`}
                    position="start"
                  >
                    <p className="tooltiptext">
                      Find words that start with these letters (AB → Able)
                    </p>
                    {starts ? (
                      <Cancel onClick={() => setState({ starts: "" })} />
                    ) : (
                      <span className="show-tooltip">
                        <HelpOutline />
                      </span>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className="text-field"
              placeholder="Z"
              id="outlined-basic"
              label="End"
              variant="outlined"
              value={ends}
              type="text"
              name="ends"
              onChange={handleChange}
              InputProps={{
                className: "text-field-input",
                endAdornment: (
                  <InputAdornment
                    className={`end-icon ${!ends && "show-tooltip"}`}
                    position="start"
                  >
                    <p className="tooltiptext">
                      Find words that end in these letters (AB → Cab)
                    </p>
                    {ends ? (
                      <Cancel onClick={() => setState({ ends: "" })} />
                    ) : (
                      <span className="show-tooltip">
                        <HelpOutline />
                      </span>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>

          <div className="row p-1 " style={{ justifyContent: "space-evenly" }}>
            <TextField
              className="text-field"
              placeholder="A_P"
              id="outlined-basic"
              label="Contains"
              name="contains"
              type="text"
              value={contains}
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                className: "text-field-input",
                endAdornment: (
                  <InputAdornment
                    className={`end-icon ${!contains && "show-tooltip"}`}
                    position="start"
                  >
                    <p className="tooltiptext">
                      Words that contain letters in this order (AB → Cable) or
                      in certain positions (X_S → Exes)
                    </p>
                    {contains ? (
                      <Cancel onClick={() => setState({ contains: "" })} />
                    ) : (
                      <span className="show-tooltip">
                        <HelpOutline />
                      </span>
                    )}
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              className="text-field"
              placeholder="5"
              id="outlined-basic"
              label="Length"
              value={length}
              type="number"
              name="length"
              onChange={handleChange}
              variant="outlined"
              InputProps={{
                className: "text-field-input",
                endAdornment: (
                  <InputAdornment
                    className={`end-icon ${!length && "show-tooltip"}`}
                    position="start"
                  >
                    <p className="tooltiptext">
                      Only show words with a specific length
                    </p>
                    {length ? (
                      <Cancel onClick={() => setState({ length: "" })} />
                    ) : (
                      <span className="show-tooltip">
                        <HelpOutline />
                      </span>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </div>
          {!props.mobileLayout && !props.sideLayout && (
            <FormControl
              style={{
                display: "flex",
                flexGrow: 1,
                marginTop: "10px",
                marginBottom: "20px",
              }}
              variant="outlined"
            >
              {/* <InputLabel htmlFor="filled-age-native-simple">Select API</InputLabel> */}
              <Select
                native
                name="dictionary"
                className="text-field txt-field-select"
                value={dictionary}
                onChange={handleChange}
                inputProps={{
                  name: "dictionary",
                  id: "filled-age-native-simple",
                }}
              >
                {dictionaryOptions.map((item, indx) => (
                  <option key={indx} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          )}
          {/* <Link style={{ textDecoration: "none" }} to="/result"> */}
          <button
            onClick={() => {
              setTouched(true);
              setTimeout(() => {
                setTouched(false);
              }, 2000);
              letters &&
                props.history.push({
                  pathname: "/result",
                  search: getQuery(),
                });
            }}
            type="submit"
            style={{
              display: "flex",
              flexWrap: "wrap",
              minWidth: "100%",
              color: "#353D50",
              textTransform: "uppercase",
              alignItems: "center",
              justifyContent: "center",
              // height: "50px",
              border: "none",
              textDecoration: "none",
              marginTop: props.mobileLayout && "14px",
              fontSize: "18px",
              // padding: "10px",
              backgroundColor: "var(--light-yellow)",
              borderRadius: "30px",
              boxShadow: "0 4px 0 var(--dark-yellow)",
            }}
            className={`btn searchbtn btn-primary ${
              props.sideLayout ? "mt-2" : ""
            }`}
          >
            Search
          </button>
          {/* </Link> */}
        </div>
        <div></div>
      </div>
      {props.mobileLayout && (
        <div className="d-flex mt-3">
          <FormControl
            style={{
              display: "inline-block",
              // flexGrow: 1,
              marginTop: "10px",
              marginBottom: "0px",
            }}
            variant="outlined"
          >
            {/* <InputLabel htmlFor="filled-age-native-simple">Select API</InputLabel> */}
            <Select
              native
              name="dictionary"
              className="txt-field-select-mobile"
              value={dictionary}
              onChange={(e) => {
                handleChange(e);
                props.changeQuery &&
                  props.changeQuery("dictionary", e.target.value);
              }}
              inputProps={{
                name: "dictionary",
                id: "filled-age-native-simple",
              }}
            >
              {dictionaryOptions.map((item, indx) => (
                <option key={indx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
          <FormControl
            style={{
              display: "inline-block",
              // flexGrow: 1,
              marginTop: "10px",
              marginBottom: "0px",
              marginLeft: "auto",
            }}
            variant="outlined"
          >
            {/* <InputLabel htmlFor="filled-age-native-simple">Select API</InputLabel> */}
            <Select
              native
              name="sort"
              className="txt-field-select-mobile"
              value={sort}
              onChange={(e) => {
                handleChange(e);
                props.changeQuery && props.changeQuery("sort", e.target.value);
              }}
              inputProps={{
                name: "sort",
                id: "filled-age-native-simple",
              }}
            >
              {sortOptions.map((item, indx) => (
                <option key={indx} value={item.value}>
                  {item.label}
                </option>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </form>
  );
}

export default withRouter(Filters);
